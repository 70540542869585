import { otherField } from './otherField';

const addArrival = (autoAddMechanic) => {
  const forms = document.querySelectorAll('#arrival-form');
  forms.forEach(form => {
    const addButtons = form.querySelectorAll('.add-fields');
    if (addButtons.length > 0) {
      const addButton = addButtons[addButtons.length - 1];
      const hasListener = addButton.getAttribute('data-listener');
      if (!hasListener) {
        const list = form.querySelector('#arrival-list');
        addButton.addEventListener('click', (e) => {
          e.preventDefault();
  
          const time = new Date().getTime();
          const id = addButton.getAttribute('data-id');
          const regexp = new RegExp(id, 'g');
          const fields = addButton.getAttribute('data-fields').replace(regexp, time);
          list.insertAdjacentHTML( 'beforeend', fields );
          const el = list.lastChild;
          
          // const removeButton = el.querySelector('.remove-fields');
          // removeButton.addEventListener('click', (e) => {
          //   e.preventDefault();
          //   el.remove();
          // });
  
          otherField();
        });
        addButton.setAttribute('data-listener', true);
        if (autoAddMechanic) {
          addButton.click();
        }
      }
    }    
  });
};

export { addArrival };