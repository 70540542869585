const filters = () => {
  const timePeriodSelect = document.querySelector('#reportrange');
  const buildingSelect = document.querySelector('.filters #building');
  const deviceSelect = document.querySelector('.filters #device');
  const preventativeMaintenanceSelect = document.querySelector('.dashboard-panel__filters #preventative_maintenance');

  if (timePeriodSelect && buildingSelect) {
    buildingSelect.addEventListener('change', () => {
      const startDate = timePeriodSelect.getAttribute('data-start');
      const endDate = timePeriodSelect.getAttribute('data-end');
      const building = buildingSelect.value;
      const path = timePeriodSelect.getAttribute('data-path');
      if (preventativeMaintenanceSelect) {
        const monthly = preventativeMaintenanceSelect.value === 'Monthly' ? 1 : 0;
        window.location.href = `${path}?start_date=${startDate}&end_date=${endDate}&building=${building}&monthly=${monthly}`;
      } else {
        window.location.href = `${path}?start_date=${startDate}&end_date=${endDate}&building=${building}`;
      }
    });

    if (deviceSelect) {
      deviceSelect.addEventListener('change', () => {
        const startDate = timePeriodSelect.getAttribute('data-start');
        const endDate = timePeriodSelect.getAttribute('data-end');
        const building = buildingSelect.value;
        const device = deviceSelect.value;
        const path = timePeriodSelect.getAttribute('data-path');
        if (preventativeMaintenanceSelect) {
          const monthly = preventativeMaintenanceSelect.value === 'Monthly' ? 1 : 0;
          window.location.href = `${path}?start_date=${startDate}&end_date=${endDate}&building=${building}&device=${device}&monthly=${monthly}`;
        } else {
          window.location.href = `${path}?start_date=${startDate}&end_date=${endDate}&building=${building}&device=${device}`;
        }
      });

      if (preventativeMaintenanceSelect) {
        preventativeMaintenanceSelect.addEventListener('change', () => {
          const startDate = timePeriodSelect.getAttribute('data-start');
          const endDate = timePeriodSelect.getAttribute('data-end');
          const building = buildingSelect.value;
          const device = deviceSelect.value;
          const monthly = preventativeMaintenanceSelect.value === 'Monthly' ? 1 : 0;
          const path = timePeriodSelect.getAttribute('data-path');
          window.location.href = `${path}?start_date=${startDate}&end_date=${endDate}&building=${building}&device=${device}&monthly=${monthly}`;
        });
      }
    }
  }
};

export { filters };