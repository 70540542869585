const initTicket = () => {
  const tickets = document.querySelectorAll('.ticket');
  for (const ticket of tickets) {
    const expandButton = ticket.querySelector('.ticket__expand');
    expandButton.addEventListener('click', (e) => {
      console.log('expand')
      e.preventDefault();
      ticket.classList.toggle('expanded');
    });

    const collapseButton = ticket.querySelector('.ticket__collapse');
    collapseButton.addEventListener('click', (e) => {
      e.preventDefault();
      ticket.classList.toggle('expanded');
    });
  }
};

export { initTicket };