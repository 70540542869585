const dropdown = () => {
  const dropdown = document.querySelector('.dropdown');
  if (dropdown) {
    const button = dropdown.querySelector('button');
    const list = document.querySelector('ul');
      button.addEventListener('click', (e) => {
        e.preventDefault();
        dropdown.classList.toggle('expanded');
      });
  }
};

export { dropdown };