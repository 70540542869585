const updateView = (textField, selectedValue, value) => {
  if (selectedValue === value) {
    textField.style.display = 'block';
  } else {
    textField.style.display = 'none';
  }
};

const dependsOn = (textField, select, value) => {
  select.addEventListener('change', (event) => {
    updateView(textField, event.target.value, value);
  });
  updateView(textField, select.value, value);
};

const otherField = () => {
  const otherInputs = document.querySelectorAll('*[data-depends-on]');
  otherInputs.forEach(input => {
    const master = input.getAttribute('data-depends-on');
    const value = input.getAttribute('data-depends-on-value');
    const select = document.querySelector(`#${master}`);
    dependsOn(input, select, value);
  });
};

export { otherField };