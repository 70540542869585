import moment from 'moment';
import daterangepicker from 'daterangepicker';

const dateRange = () => {

  const buildingSelect = document.querySelector('.filters #building');
  const dateSelect = document.querySelector('#reportrange');
  const preventativeMaintenanceSelect = document.querySelector('.dashboard-panel__filters #preventative_maintenance');

  if (!dateSelect) return;

  const path = dateSelect.getAttribute('data-path') || '/';
  const startAttribute = dateSelect.getAttribute('data-start');
  const endAttribute = dateSelect.getAttribute('data-end');
  const minAttribute = dateSelect.getAttribute('data-min');
  let start;
  let end;
  let min = null;
  if (startAttribute === 'all') {
    start = moment('1970-01-01');
  } else {
    start = moment(startAttribute);
  }
  if (endAttribute === 'all') {
    end = moment();
  } else {
    end = moment(endAttribute);
  }
  if (minAttribute) {
    min = moment(minAttribute);
  }

  function updateView(start, end) {
    console.log('update view', start)
    if (start.isSame(moment('1970-01-01'))) {
      $('#reportrange span').html('All Time');
    } else {
      $('#reportrange span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
    }
  }

  function cb(start, end) {
    updateView(start, end);
    const building = buildingSelect.value;
    let startDate;
    let endDate;
    if (start.isSame(moment('1970-01-01'))) {
      startDate = 'all';
      endDate = 'all';
    } else {
      startDate = start.format('YYYY-MM-DD');
      endDate = end.format('YYYY-MM-DD');
    }
    if (preventativeMaintenanceSelect) {
      const monthly = preventativeMaintenanceSelect.value === 'Monthly' ? 1 : 0;
      window.location.href = `${path}?start_date=${startDate}&end_date=${endDate}&building=${building}&monthly=${monthly}`;
    } else {
      window.location.href = `${path}?start_date=${startDate}&end_date=${endDate}&building=${building}`;
    }
  } 
  
  new daterangepicker('#reportrange', {
    startDate: start,
    endDate: end,
    minDate: min,
    ranges: {
      'Month to Date': [moment().startOf('month'), moment()],
      'Previous Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
      'Last 12 Months': [moment().subtract(12, 'month'), moment()],
      'Previous Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
      'All Time': [moment('1970-01-01'), moment()]
    }
  }, cb);

  updateView(start, end);

};

export { dateRange };