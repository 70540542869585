// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import 'chartkick/chart.js';
// import 'daterangepicker/daterangepicker.css';

import { menu } from './menu';
import { dropdown } from './dropdown';
import { filters } from './filters';
import { printPage } from './printPage';
import { otherField } from './otherField';
import { removeItem } from './removeItem';
import { addCall } from './addCall';
import { addVisit } from './addVisit';
import { addArrival } from './addArrival';
import { addDeparture } from './addDeparture';
import { addThreshold } from './addThreshold';
import { initTicket } from './ticket';

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import { dateRange } from './dateRange';

document.addEventListener('turbolinks:load', () => {
  menu();
  dropdown();
  filters();
  printPage();
  otherField();
  removeItem();
  addCall();
  addVisit();
  addArrival();
  addDeparture();
  addThreshold();
  dateRange();
  initTicket();
});
