const addThreshold = () => {
  const forms = document.querySelectorAll('#threshold-form');
  forms.forEach(form => {
    const addButtons = form.querySelectorAll('#add-threshold');
    if (addButtons.length > 0) {
      const addButton = addButtons[addButtons.length - 1];
      const list = form.querySelector('#threshold-list');
      addButton.addEventListener('click', (e) => {
        e.preventDefault();

        const time = new Date().getTime();
        const id = addButton.getAttribute('data-id');
        const regexp = new RegExp(id, 'g');
        const fields = addButton.getAttribute('data-fields').replace(regexp, time);
        list.insertAdjacentHTML( 'beforeend', fields );
        const el = list.lastChild;

        const dayField = el.querySelector(`#building_thresholds_attributes_${time}_day`);
        dayField.value = addButton.getAttribute('data-day');

        const emergencyField = el.querySelector(`#building_thresholds_attributes_${time}_emergency`);
        emergencyField.value = addButton.getAttribute('data-emergency');

        const removeButton = el.querySelector('.remove-fields');
        removeButton.addEventListener('click', (e) => {
          e.preventDefault();
          el.remove();
        });
      });
    }
  });
};

export { addThreshold };