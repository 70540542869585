const printPage = () => {
  const printButton = document.querySelector('#print-button');
  if (printButton) {
    printButton.addEventListener('click', (e) => {
      e.preventDefault();
      window.print();
    });
  }
};

export { printPage };