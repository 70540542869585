const menu = () => {
  const menuButton = document.querySelector('.navbar-menu');
  const sidebar = document.querySelector('.sidebar-nav');
  const container = document.querySelector('.container');
  if (menuButton) {
    menuButton.addEventListener('click', (e) => {
      e.preventDefault();
      sidebar.classList.toggle('expanded');
      container.classList.toggle('expanded');
    });
  }
};

export { menu };