const removeItem = () => {
  const items = document.querySelectorAll('.removable-item');
  items.forEach(item => {
    const removeButton = item.querySelector('.remove-fields');
    const destroyFields = item.querySelectorAll(':scope > input[type=hidden]');
    if (destroyFields.length > 0) {
      const idField = destroyFields[0];
      const destroyField = destroyFields[destroyFields.length - 1];
      if (removeButton) {
        removeButton.addEventListener('click', (e) => {
          e.preventDefault();
          
          // remove all associations
          item.querySelectorAll('.removable-item').forEach(child => {
            child.parentElement.removeChild(child);
          });

          // if it exists in db, destroy it
          if (idField.value) {
            destroyField.value = 'true';
            item.style.display = 'none';
          } else {
            // just remove it from dom
            item.remove();
          }
          
          
        });
      }
    }
  });
};

export { removeItem };